import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '',
        name: 'IndexView',
        component: () => import('../views/IndexView.vue'),
        meta: {
          title: '总览 / 后台管理系统'
        }
      },
      {
        path: '/dashboard',
        component: () => import('../views/DashBoardView.vue'),
        meta: {
          title: '用户管理 / 后台管理系统'
        }
      },
      {
        path: '/sys',
        component: () => import('../views/SysBoardView.vue'),
        meta: {
          title: '系统管理 / 后台管理系统'
        }
      },
      {
        path: '/about',
        component: () => import('../views/AboutView.vue'),
        meta: {
          title: '关于管理 / 后台管理系统'
        }
      },
      {
        path: '/empty',
        alias: '/test',
        component: () => import('../views/EmptyView.vue'),
        meta: {
          title: '后台管理系统'
        }
      },
      {
        path: '/my',
        component: () => import('../views/UserInfoView.vue'),
        meta: {
          title: '个人中心 / 后台管理系统'
        }
      },
      {
        path: '/security',
        component: () => import('../views/SecurityView.vue'),
        meta: {
          title: '后台管理系统'
        }
      },
      {
        path: '/management',
        component: () => import('../views/ManagementView.vue'),
        meta: {
          title: '后台管理系统'
        }
      },
    ]
  },
  {
    path: '/header',
    component: () => import('@/components/Header/src/Header.vue')
  },
  {
    path: '/aside',
    component: () => import('@/components/Aside/src/Aside.vue')
  },
  {
    path: '/main',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/login',
    alias: '/',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '后台管理系统'
    }
  },
  
  // 404 页面
  {
    path: '*',
    component: () => import('../views/404.vue'),
    meta: {
      title: '啊哦，页面走丢咯...'
    }
  }
  
  
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //路由发生改变时,触发
  // 设置页面标题
  window.document.title = undefined ? '后台管理系统' : to.meta.title;
  next();
});

export default router
