/**
 * --------------------------------------------------------------------------
 * Jouska (v1.0.0): aside.js
 * --------------------------------------------------------------------------
 */

import $ from 'jquery'


/**
 * Constants
 */

const el_aside = $(".aside-container") // aside
const btn_collapse_show = $(".nav-collapse") // 折叠按钮
const el_nav_item = $(".custom-nav-item")
const el_nav_icon = $(".nav-item-icon")

$(function () {
    console.log("aside.js");
    // 点击展开/折叠
    btn_collapse_show.on('click', function () {
        console.log('点击了');
        // 使用 hasClass 检查元素是否包含类名 'show'
        if (el_aside.hasClass('show')) {
            // 如果包含类名 'show'，则移除该类名
            el_aside.removeClass('show');
        } else {
            // 如果不包含类名 'show'，则添加该类名
            el_aside.addClass('show');
        }
    });
    // 点击选项 添加 'active'
    el_nav_item.on('click', function() {
        // 使用 $(this) 获取当前点击的元素
        let currentNavItem = $(this);
        let currentNavIcon = $(this).find(el_nav_icon);
        el_nav_item.removeClass('active')
        el_nav_icon.removeClass('active')
        currentNavItem.addClass('active')
        currentNavIcon.addClass('active')
    });
})
