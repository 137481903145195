import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入bootstrap的css和js
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'

// 导入 element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 导入自定义全局样式
import './assets/css/reset.css'
import './assets/js/aside.js'

// 自定义style.css
import './assets/css/style.css'

import moment from 'moment'

Vue.use(ElementUI);
Vue.config.productionTip = false


moment.locale();

// 日期格式化 YYYY-MM-DD hh:mm:ss -> YYYY-MM-DD
Vue.filter('formatDateYYYYMMDD', function(value) {
  const formerTime = moment(value);
  return formerTime.format("YYYY-MM-DD");
});


// 日期格式化 YYYY-MM-DD hh:mm:ss -> YYYY-MM-DD HH:mm:ss
Vue.filter('formatDateYYYYMMDDhhmmss', function(value) {
  const formerTime = moment(value);
  return formerTime.format("YYYY-MM-DD HH:mm:ss");
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
  