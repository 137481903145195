import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 开发环境
    // baseURL: 'http://localhost:7788/',
    // 生产环境
    baseURL: 'http://api.metabyte.top:7788/',
    tokenValue: null
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    admin
  }
})
