export default {
    // 命名空间
    namespaced: true,
    state: {
        token: null
    },
    mutations: {
        setTokenValue(state, token) {
            state.token = token;
            console.log("mutation执行setTokenValue函数: " + state.token);
        }
    }
}